import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function EditorialTable({rows}) {
  return (
    <TableContainer sx={{boxShadow: "none", maxWidth: 650 }}>
      <Table  aria-label="simple table">
        <TableBody>
          {rows && rows.map((row) => (
            <TableRow 
              key={row.name}
              sx={{ boxShadow: "none",'&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{boxShadow: "none", fontWeight:"bold" }} align="left">{row.name}</TableCell>
              <TableCell sx={{boxShadow: "none" }} align="left">{row.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
