import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

export default function NewsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const newsUpdate = () => {
    navigate("/news-update");
    setAnchorEl(null);
  };
  const membershipUpdate = () => {
    navigate("/membership-update");
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        sx={{ textTransform: "none", color: "white" }}
        onClick={handleClick}
      >
        NEWS
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={newsUpdate}>News Update</MenuItem>
        <MenuItem onClick={membershipUpdate}>Membership Update</MenuItem>
      </Menu>
    </div>
  );
}
