import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import membership from "../components/membership_update.pdf";
import Box from "@mui/material/Box";

const MembershipUpdate = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ m: 2 }}>
        <iframe
          src={`${membership}#view=fitH`}
          title="membership"
          width="100%"
          height="1200px"
        ></iframe>
      </Box>
      <Footer />
    </>
  );
};
export default MembershipUpdate;
