import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Contact() {
  return (
    <>
      <Navbar />
      <Box sx={{ m: 2 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            borderLeft: "solid 2px green",
            p: 1,
            mt: 4,
            mb: 4,
          }}
          variant="h5"
          component="h5"
          gutterBottom
        >
          Contact
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Editor-in-Chief</b> <br />
          Dr. J.K. Opele, <br />
          National Centre for Technology Management,
          <br />
          Obafemi Awolowo University, Ile-Ife. <br />
          <b>Mobile</b>: 08034906312 <br />
          <b>Email</b>: opelejk@gmail.com
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
          <b>Assistant Editor</b>
          <br />
          Dr. W. A. Aboyade,
          <br />
          Hezekiah Oluwasanmi Library,
          <br />
          Obafemi Awolowo University, Ile-Ife.
          <br />
          <b>Mobile</b>: 08063180909
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
          <b>Our email:</b> <br /> livingspringjournal@gmail.com
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
