import React from "react";
import Navbar from "../components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Footer from "../components/Footer";
import EditorialTable from "../components/EditorialTable";

export default function About() {
  const typo = (content) => (
    <Typography
      variant="body2"
      sx={{
        color: "#004526",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: 2,
        p: 1,
        bgcolor: "rgba(154, 205, 50,0.2)",
      }}
    >
      {content}
    </Typography>
  );
  const editorialLst = [
    { name: typo("Editor-in-Chief:"), desc: "" },
    {
      name: "Dr. J.K. Opele",
      desc: "National Centre for Technology Management, Obafemi Awolowo University, Ile-Ife.",
      contact: (
        <>
          <br />
          <b>Mobile</b>: 08034906312 <br />
          <b>Email</b>: opelejk@gmail.com
        </>
      ),
    },
    { name: typo("Assistant Editor:"), desc: "" },
    {
      name: "Dr. W. A. Aboyade",
      desc: "Hezekiah Oluwasanmi Library, Obafemi Awolowo University, Ile-Ife",
      contact: (
        <>
          <br />
          <b>Mobile</b>: 08063180909
          <b>Email</b>: abowakan@gmail.com
        </>
      ),
    },

    { name: typo("Business Manager:"), desc: "" },
    { name: "Mr. B.C. Isola", desc: "Federal Polytechnic, Ede Osun State." },
    { name: typo("Financial Secretary/Treasurer:"), desc: "" },
    {
      name: "Mrs. A.R. Akinniyi",
      desc: "Hezekiah Oluwasanmi Library, Obafemi Awolowo University, Ile-Ife",
    },
    { name: typo("Editorial Advisers:"), desc: "" },
    { name: "Dr. E.I. Ifidon:", desc: "Ambrose Alli University, Edo State" },
    {
      name: "Dr. J.A. Kadir",
      desc: "Taisolarin University of Education, Ogun State",
    },
    {
      name: "Dr.  T.M. Salisu",
      desc: "Taisolarin University of Education, Ogun State",
    },
    {
      name: "Professor A.O. Issa",
      desc: "University of Ilorin, Kwara State",
    },
    {
      name: "Prof. C. Omekwu",
      desc: "University of Nigeria, Nsuka, Anambra State",
    },
    {
      name: "Prof. E. Otong",
      desc: "University of Calabar, Cross-River State",
    },
    {
      name: "Dr. F.Z. Oguntuase",
      desc: "Obafemi Awolowo University, Ile-Ife, Osun State",
    },
    {
      name: "Prof. G.O. Alegbeleye",
      desc: "Babcock University, Ilishan-Remo Ogun State",
    },
    {
      name: "Prof. I. Oketunji",
      desc: "Adeleke University, Ede, Osun State",
    },
    {
      name: "Prof. M.I.",
      desc: "Ajibero: University of Ilorin, Kwara State",
    },
    {
      name: "Prof. N. Adetoro",
      desc: "Taisolarin University of Education, Ogun State",
    },
    {
      name: "Prof. R.O. Opeke",
      desc: "Babcock University, Ilishan-Remo, Ogun State",
    },
    {
      name: "Prof. S.O. Popoola",
      desc: "University of Ibadan, Oyo State",
    },
  ];

  return (
    <>
      <Navbar />
      <Box sx={{ m: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                maxWidth: 600,
                borderLeft: "solid 2px green",
                p: 1,
              }}
              variant="h4"
              component="h4"
              gutterBottom
            >
              About the Journal
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ textAlign: "justify", lineHeight: 2 }}
            >
              Livingspring Journal of Library and Information Science is a
              peer-reviewed journal that publishes scholarly articles and essays
              that make clear contributions to the scholarship of theory and
              practice in library and information science field. We publish
              original articles in all aspects of library and information
              science. Hence, contributions that are critical, empirical,
              well-researched and issues from theoretical, practice-based or
              analytical angles are welcomed, as well as contributions that
              focus on innovative and reflective approaches to knowledge, theory
              and practice of librarianship. The Journal aims to provide a
              stimulating and challenging forum for contributors to describe,
              theorise and reflect on their area of calling and is particularly
              interested in contributions that have relevance to local,
              national, continental and global context.
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src="/osun-logo.png"
              style={{
                padding: 2,
                borderRadius: 10,
              }}
            />
          </Box>
        </Stack>
        <Typography
          sx={{
            fontWeight: "bold",
            maxWidth: 600,
            borderLeft: "solid 2px green",
            p: 1,
          }}
          variant="h4"
          component="h4"
          gutterBottom
        >
          Editorial Board
        </Typography>
        <EditorialTable rows={editorialLst} />
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mt: 3, textAlign: "justify" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              maxWidth: 600,
              borderLeft: "solid 2px green",
              p: 1,
            }}
            variant="h4"
            component="h4"
            gutterBottom
          >
            Editorial
          </Typography>
          <p>
            It is with great pride and joy that we introduce the maiden edition
            of the LivingspringJournal of Library and Information Science
            (LSJLIS), an official publication of the Osun State Chapter of
            Nigerian Library Association. The editorial members of this maiden
            edition were recommended on 5th of April, 2017 during the state
            ordinary general meeting held at the Hezekiah Oluwasanmi Library,
            Obafemi Awolowo University, Ile-Ife.
          </p>
          <p>
            The Editor-in-Chief and the Associate Editor have PhD in the fields
            of librarianship and information resources management with rich
            experiences in scholarly communication. The Editor-in-Chief, Dr
            Jacob Opele bagged his PhD information resources management at the
            prestigious foremost faith based Babcock University, Ilishan-Remo,
            Ogun State with specialization in knowledge management. The
            Associate Editor has a PhD in librarianship at the premier
            University of Ibadan, Ibadan, Oyo State. Both have published in top
            professional and academic journals locally and internationally.
            Their appointment as the pioneer editorial board members, has
            equally added to the quality of the articles in this first edition
            as well as its acceptability in the academic community. Other
            members hold masters’ degree in library, archival and information
            studies at the University of Ibadan, Ibadan, OyoState. They have
            practiced librarianship for more than two decades. Their unique
            experiences had also contributed to the success of this first
            edition.
          </p>
          <p>
            The Livingspring Journal of Library and Information Science is a
            peer-reviewed print and electronic journal that publishes scholarly
            articles and essays that make clear contributions to the scholarship
            of theory and practice, knowledge management, records and archive
            management, health information management and originalarticles in
            all aspects of library and information science. Furthermore,
            emphases are given to articles that are critical, empirical,
            well-researched and theoretical, practice-based and analytical.
            Contributions that focus on innovative and reflective approaches to
            knowledge, theory, practice, current, past, and emerging theories of
            librarianship and library practice, as well as reports of
            successful, innovative, or experimental library procedures, methods,
            or projects in all areas of librarianship, set in the context of
            applied research are welcome.
          </p>
          <p>
            The Journal aims to provide a stimulating and challenging forum for
            contributors to describe, theorise and reflect on their area of
            calling and is particularly interested in contributions that have
            relevance to local, national, continental and global context.There
            are six articles in this maiden edition comprising:
          </p>
          <ol>
            <li>
              Influence of school libraries on academic achievement of students
              in selectedsecondary schools in Ilesa, Osun State, Nigeria;
            </li>
            <li>
              An assessment of the influence of academic qualification and work
              experience on employment of staff in two federal university
              libraries in South-West Nigeria;
            </li>
            <li>
              Planning school library services for students’ satisfaction in
              public secondary schools in Imo State;
            </li>
            <li>
              Utilization of indigenous knowledge and quality of life of rural
              people in Idemili South Local Government Area of Anambra State,
              Nigeria;
            </li>
            <li>
              Information literacy skills for sustainable development goals in
              the 21st century Nigeria.
            </li>
            <li>Osun State University Library system: The story so far.</li>
          </ol>
          <b>Jacob Opele, PhD</b>
          <br />
          <i>Editor-in-Chief</i>
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
