import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export default function MobileMenuBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const navigate = useNavigate();

  const list = (
    <Box
      sx={{ width: "auto", fontFamily: "roboto" }}
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textDecoration: "none" }}>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/");
              }}
            >
              HOME
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/about");
              }}
            >
              ABOUT
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/news-update");
              }}
            >
              NEWS UPDATE
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/membership-update");
              }}
            >
              MEMBERSHIP UPDATE
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/for-contributors");
              }}
            >
              FOR CONTRIBUTORS
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              variant="button"
              onClick={() => {
                navigate("/contact");
              }}
            >
              CONTACT
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Tooltip title="Menu">
          <IconButton
            sx={{ color: "white" }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
