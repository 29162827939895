import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Notes = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ m: 2 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            maxWidth: 600,
            borderLeft: "solid 2px green",
            my: 3,
          }}
          variant="h4"
          component="h4"
          gutterBottom
        >
          Notes to Contributors
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "justify" }}>
          Livingspring Journal of Library and Information Science is a
          peer-reviewed journal that publishes scholarly articles and essays
          that make clear contributions to the scholarship of theory and
          practice in library and information science field. We publish original
          articles in all aspects of library and information science. Hence,
          contributions that are critical, empirical, well-researched and issues
          from theoretical, practice-based or analytical angles are welcomed, as
          well as contributions that focus on innovative and reflective
          approaches to knowledge, theory and practice of librarianship. The
          Journal aims to provide a stimulating and challenging forum for
          contributors to describe, theorise and reflect on their area of
          calling and is particularly interested in contributions that have
          relevance to local, national, continental and global context. The
          editorial board also welcomes innovative articles that redefine
          information resources management. Authors are required to submit their
          papers electronically in Word document format to
          livingspringjournal@gmail.com or abowakan@gmail.com
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            maxWidth: 600,
            borderLeft: "solid 2px green",
            my: 3,
          }}
          variant="h4"
          component="h4"
        >
          General Format
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "justify" }}>
          (a) Title of paper, name of author(s), affiliation of author(s),
          e-mail and telephone number of leading author (if more than one).
          <br />
          (b) Font and size: (Times New Roman, size 12).
          <br />
          (c) Abstract: (Not more than 250 words).
          <br />
          (d) Keywords: (Not more than five (5).
          <br />
          (e) Body of text: Introduction; Literature; Methodology; Results and
          Discussion; Conclusion; Recommendations, References, Tables and graphs
          (Not more than 4000 Words).
          <br />
          (f) References: American Psychological Association (APA) citation
          Style, 6th ed., 2010 (www.apastyle.org).
          <br />
          (g) Table(s) and figure(s) should be embedded in the text and
          discussed immediately after presentation.
          <br />
          (i) Assessment fee = N5,000.00: Publication fee = N15,000.00.
        </Typography>
      </Box>
      <Footer />
    </>
  );
};
export default Notes;
