import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/api";
import Stack from "@mui/material/Stack";
import Footer from "../components/Footer";

export default function Home() {
  const [journals, setJournals] = useState("");

  useEffect(() => {
    const retrievJournlas = async () => {
      try {
        const response = await axios.get(`${API_URL}/journal`);
        setJournals(response.data);
      } catch (error) {
        console.error(error);
        setJournals("An error occurred while retieving journals.");
      }
    };
    // retrievJournlas();
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 3,
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#004526",
              fontWeight: "bold",
              fontFamily: "Arial, Helvetica, sans-serif",
              textAlign: { xs: "center", md: "left" },
            }}
            variant="h3"
            component="h3"
            gutterBottom
          >
            Livingspring Journal of Library and Information Science (LJLIS):
          </Typography>
          <Typography
            sx={{
              color: "#FFFF00",
              maxWidth: 600,
              fontFamily: "'Times New Roman', Times, serif",
              textShadow:
                "1px 0 #004526, -1px 0 #004526, 0 1px #004526, 0 -1px #004526,1px 1px #004526, -1px -1px #004526, 1px -1px #004526, -1px 1px #004526",
              textAlign: { xs: "center", md: "left" },
            }}
            variant="h4"
            component="h4"
            gutterBottom
          >
            Journal of the Nigerian Library Association of Osun State Chapter
          </Typography>
        </Box>
        <img src="/osun-logo.png" />
      </Stack>
      <Stack
        sx={{
          p: 3,
          background:
            "linear-gradient(180deg, rgba(0,98,65,0.02) 7%, rgba(118,216,147,0.01962359943977591) 37%, rgba(152,251,152,0.001) 64%, rgba(208,240,192,0.001) 93%)",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            mt: 1,
            mb: 1,
          }}
          variant="h6"
          component="h6"
          gutterBottom
        >
          ISSN: 2705-3644 - Online
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            mt: 1,
            mb: 1,
          }}
          variant="h6"
          component="h6"
          gutterBottom
        >
          ISSN: 2705-3687 - Print
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          // display: "flex",
          // flexWrap: "wrap",
          p: 3,
          backgroundImage:
            "linear-gradient(109.6deg, rgba(148, 233, 194, 0.04) 11.2%, rgba(224, 235, 186,0.2) 91.4%)",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              borderLeft: "solid 2px #FEBE10",
              p: 1,
              mt: 4,
              mb: 4,
            }}
            variant="h5"
            component="h5"
            gutterBottom
          >
            New Journals
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography gutterBottom>Volume 1</Typography>

              <a href="/pdfs/vol1.pdf" download>
                Download&nbsp;&nbsp;
                <img
                  src="/images/pdf3.png"
                  style={{ height: 20, width: 20 }}
                  alt=" download"
                />{" "}
              </a>
            </Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography gutterBottom>Volume 2</Typography>

              <a href="/pdfs/vol2.pdf" download>
                Download&nbsp;&nbsp;
                <img
                  src="/images/pdf3.png"
                  style={{ height: 20, width: 20 }}
                  alt=" download"
                />{" "}
              </a>
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // flexWrap: "wrap",
            }}
          >
            {journals ?
              journals.map(({journalId, title,file_path}) => (
                <Box 
                  onClick={() => navigate("/journal-details",{state: { journalId },})}
                  justifyContent="centre" 
                  sx={{p:1, m:1, bgcolor:"rgba(70, 130, 180, 0.1)", 
                  borderRadius:5, maxWidth:450,"&:hover":{ bgcolor:"white",border: "solid 1px #40E0D0"}
                  }}>
                    <Typography gutterBottom variant="body">
                      {title}
                    </Typography>
                     
                    <img 
                    style={{maxWidth:200, height:200,objectFit: "contain",cursor:"pointer"}}
                    src={`${API_URL}/${file_path}`.replace("/api", "")}
                    alt=""
                    onClick={() =>     
                    navigate("/journal-details",{
                              state: { journalId },
                            })
                          }/>
                  </Box>
                 
                
            )):"No New Journals"}
          </Box> */}

          <Typography
            sx={{
              fontWeight: "bold",
              maxWidth: 600,
              borderLeft: "solid 2px #FEBE10",
              p: 1,
              mt: 4,
              mb: 4,
            }}
            variant="h5"
            component="h5"
            gutterBottom
          >
            Previous Journals
          </Typography>
        </Box>
        {/* ////////////////////////sx={{ flexBasis: 1, flexGrow: 1, flexShrink: 1 }} */}
        <Box sx={{ maxWidth: 900 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              maxWidth: 600,
              borderLeft: "solid 2px #FEBE10",
              p: 1,
              mt: 4,
              mb: 4,
            }}
            variant="h5"
            component="h5"
            gutterBottom
          >
            About the Journal
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ textAlign: "justify", lineHeight: 2 }}
          >
            Livingspring Journal of Library and Information Science is a
            peer-reviewed journal that publishes scholarly articles and essays
            that make clear contributions to the scholarship of theory and
            practice in library and information science field. We publish
            original articles in all aspects of library and information science.
            Hence, contributions that are critical, empirical, well-researched
            and issues from theoretical, practice-based or analytical angles are
            welcomed, as well as contributions that focus on innovative and
            reflective approaches to knowledge, theory and practice of
            librarianship. The Journal aims to provide a stimulating and
            challenging forum for contributors to describe, theorise and reflect
            on their area of calling and is particularly interested in
            contributions that have relevance to local, national, continental
            and global context.
            <Button
              variant="text"
              style={{
                color: "#004526",
                textTransform: "none",
              }}
              onClick={() => navigate("/about")}
            >
              Learn More
            </Button>
          </Typography>
        </Box>
      </Stack>
      <Footer />
    </>
  );
}
// bgColor:"background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));"
// "linear-gradient(315deg, #8ae9b3 0%, #c8d6e5 74%)"
// background: linear-gradient(109.6deg, rgb(148, 233, 194) 11.2%, rgb(224, 235, 186) 91.1%);
