import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import MobileMenuBar from "./MobileMenuBar";
import NewsMenu from "../components/NewsMenu";
import { Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { lime } from "@mui/material/colors";

const pages = ["About", "Archive"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNav = (nav) => {
    navigate(`/${nav.toLowerCase()}`);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElNav(null);
  };
  const navigate = useNavigate();
  const handleProfile = () => {
    setAnchorElUser(null);
    navigate("/profile");
  };
  const handleLogOut = () => {
    setAnchorElUser(null);
    navigate("/");
  };
  const myLogo = (
    <img
      src="/logo.png"
      style={{
        width: 50,
        height: 50,
        background: "white",
        padding: 2,
        borderRadius: 10,
        cursor: "pointer",
      }}
    />
  );
  return (
    <AppBar position="static" sx={{ bgcolor: "#004526" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              borderRadius: 30,
            }}
            onClick={() => navigate("/")}
          >
            {myLogo}
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Livingspring Journal
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MobileMenuBar />
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 1,
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            {myLogo}
          </Box>
          <Stack
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              lineHeight: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <span>Livingspring Journal</span>
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <MenuItem>
              <Typography
                variant="button"
                onClick={() => {
                  navigate("/");
                  setAnchorElNav(null);
                }}
              >
                HOME
              </Typography>
            </MenuItem>

            <MenuItem>
              <Typography
                variant="button"
                onClick={() => {
                  navigate("/about");
                  setAnchorElNav(null);
                }}
              >
                ABOUT
              </Typography>
            </MenuItem>
            <MenuItem>
              <NewsMenu />
            </MenuItem>

            <MenuItem>
              <Typography
                variant="button"
                onClick={() => {
                  navigate("/for-contributors");
                  setAnchorElNav(null);
                }}
              >
                FOR CONTRIBUTORS
              </Typography>
            </MenuItem>

            <MenuItem>
              <Typography
                variant="button"
                sx={{ cursor: "ponter" }}
                onClick={() => {
                  navigate("/contact");
                  setAnchorElNav(null);
                }}
              >
                CONTACT
              </Typography>
            </MenuItem>
          </Box>

          <Box sx={{ ml: 1, flexGrow: 0 }}>
            <Tooltip title="Search">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <SearchIcon sx={{ color: lime[700] }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
