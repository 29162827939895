import "../site-style.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="site-footer">
      <div class="container">
        <div class="footer-logo">
          <img src="/logo.png" alt="Site Logo" />
        </div>
        <div class="footer-links">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/news-update">News Update</Link>
          <Link to="/membership-update">Memebership Update</Link>
          <Link to="/for-contributors">For Contributors</Link>
          {/* <Link to="/archive">Archive</Link> */}
          <Link to="/contact">Contact</Link>
        </div>
        <div class="footer-copyright">
          © <span id="current-year">2025</span> Livingspring Journal. All rights
          reserved.
        </div>
      </div>
    </footer>
  );
}
