import React from "react";
import "./App.css";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import { StateProvider } from "./utils/app-state-context";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NewsUpdate from "./pages/NewsUpdate";
import MembershipUpdate from "./pages/MembershipUpdate";
import Notes from "./pages/Notes";

function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/home",
      element: <Home />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/about",
      element: <About />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/contact",
      element: <Contact />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/news-update",
      element: <NewsUpdate />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/membership-update",
      element: <MembershipUpdate />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/for-contributors",
      element: <Notes />,
      errorElement: <ErrorPage />,
    },
    { path: "*", element: <Home /> },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
